import React from "react"
import { Link } from "gatsby"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core"

import kebabCase from "lodash/kebabCase"

const useStyles = makeStyles(({ spacing, palette }) => ({
  styleTags: {
    borderRadius: "0 3px 3px 0",
    background: "#FFFFFF",
    borderLeft: `3px solid ${palette.primary.main}`,
    fontWeight: "bold",
    padding: "8px 16px",
    margin: spacing(1),
  },
}))

const Tags = props => {
  const classes = useStyles()
  if (!props.listOfTags.length) {
    return null
  }

  return (
    <div className="tags-bottom">
      <small>
        {props.listOfTags.map((tag, index) => (
          <Link className="tags" to={`/${kebabCase(tag)}/`} key={index}>
            <Button className={classes.styleTags}> {tag}</Button>
          </Link>
        ))}
      </small>
    </div>
  )
}

export default Tags
